import React from 'react';

const Food = () => {
 
  return (
    <div>
      
      <h1>Food</h1>
      
    </div>
  );
};

export default Food;