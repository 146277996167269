import React from 'react';
import './home.css'; // Import your existing CSS file
import profilePhoto from '../img/profilePhoto.jpg';
import instagram from '../img/instagramIcon.png';
import linkedin from '../img/linkedinIcon.png';


const Home = () => {
  return (
    <>
      <div className='home-container'>

        <div className='content-block'>

          <h1>Hi, I'm Umer Seliya</h1>
          <h2>Software Engineer</h2>
          <p>Welcome! I'm Umer, a software engineer passionate about exploring the vast field of tech. When I'm not coding, I capture life's moments through photography and savor culinary adventures. Explore my portfolio to see the intersection of technology and my creativity.</p>
          
          <div className='buttons'>
            <button className='btn'>More About Me</button>
              <a href="https://www.instagram.com/umer.seliya" target="_blank" rel="noopener noreferrer">
                <img src={instagram} alt="Instagram" className="icon"/>
              </a>
              <a href="https://www.linkedin.com/in/seliya-umer/" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt="LinkedIn" className="icon"/>
              </a>
          </div>

        </div>

        <div className='image-block'>
          <img src={profilePhoto} alt="Umer Seliya" className="profile-photo"/>
        </div>

      </div>

      
    </>
  );
};

export default Home;
