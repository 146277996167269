import Navbar from "./Navbar";
import Home from "./pages/Home";
import AboutMe from "./pages/About";
import Projects from "./pages/Projects";
import Photography from "./pages/Photography";
import Food from "./pages/Food";

import { Route, Routes } from "react-router-dom";

function App() {
  
  return (
  <>
    <Navbar/>
    <div className="container">
      <Routes>
        <Route path="/" element={ <Home />} />
        <Route path="/about" element={ <AboutMe />} />
        <Route path="/projects" element={ <Projects />} />
        <Route path="/photography" element={ <Photography />} />
        <Route path="/food" element={ <Food />} />
      </Routes>
    </div>
  </>
  
  )
}

export default App