import React from 'react';

const Photography = () => {
 
  return (
    <div>
      
      <h1>Photography</h1>
      
    </div>
  );
};

export default Photography;