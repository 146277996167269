import { Link, useMatch, useResolvedPath } from "react-router-dom"
import './Navbar.css';

export default function Navbar() {
    

    return (
        <nav className="nav">

            <div className="left-nav">

                <h1>UMER .</h1>

            </div>

            <div className="right-nav">

                <CustomLink to="/">HOME</CustomLink>
                <CustomLink to="About">ABOUT ME</CustomLink>
                <CustomLink to="Projects">CS PROJECTS</CustomLink>
                <CustomLink to="Photography">PHOTOGRAPHY</CustomLink>
                <CustomLink to="Food">FOOD</CustomLink>

            </div>
            
        </nav>
        )
    }
    
    function CustomLink({ to, children, ...props }) {
        const resolvedPath = useResolvedPath(to);
        const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    
        return (
            <div className={isActive ? "active" : ""}>
                <Link to={to} {...props}>{children}</Link>
            </div>
        );
    }