import React from 'react';
import './About.css';

const About = () => {
 
  return (
    <div>
      
      <h1>About Me</h1>
      
    </div>
  );
};

export default About;
